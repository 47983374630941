<div #modalHeader class="app-modal-header">
  <div class="modal-title-buttons">
    @for (btn of titleButtons; track $index) {
      <button type="button" class="btn btn-icon-only" (click)="btn.action()">
        <svg-icon [key]="btn.icon" size="lg"></svg-icon>
      </button>
    }
    <button type="button" class="btn btn-icon-only" (click)="close()">
      <svg-icon key="close"></svg-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h3 *ngIf="modalTitle">{{ modalTitle }}</h3>
    <ng-content select="[modalHeader]"></ng-content>
  </div>
</div>
<div class="app-modal-body scrollable-content">
  <ng-content select="[modalBody]"></ng-content>
</div>
<div *ngIf="showFooter" class="app-modal-footer">
  <ng-content select="[modalFooter]"></ng-content>
</div>
