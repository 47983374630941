import { DateTime, Duration, DurationLikeObject, Info, Interval, Settings, StringUnitLength, Zone, SystemZone } from 'luxon';
import { TextHelper } from './text.helper';

export enum DateTimeUnit {
  Year = 'year',
  Quarter = 'quarter',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Millisecond = 'millisecond',
}

export enum DateTimeFormat {
  FullDateTime12H = 'MMM d, yyyy, h:mm:ss a',
  FullDateTime24H = 'MMM d, yyyy, H:mm:ss',
  MonthDayYear_Hour24MinuteSecond = 'MM-dd-yyyy HH:mm:ss',
  MonthDayYear = 'MM-dd-yyyy',
  MonthNameDayYear = 'MMMM d, yyyy',
  MonthNameYear = 'MMMM yyyy',
  MonthNameDay = 'MMMM d',
  ShortMonthNameDay = 'MMM d',
  ShortMonthNameDayYear = 'MMM d, yyyy',
  WeekdayMonthDay = 'cccc, MMM d',
  WeekdayShortMonthDayYear = 'cccc, MMM d, yyyy',
  // Time formats - app shoud has switch to use 12h or 24h format
  Time12hFull = 'hh:mm:a',
  Time12h = 'h:mm a',
  Time12hHour = 'h a',
  Time24hHour = 'h',
  Time24h = 'HH:mm',
}

export enum ApiDateFormat {
  DateOnly = 'yyyy-MM-dd',
  DateTime = "yyyy-MM-dd'T'HH:mm:ss.SSS",
}

export enum DateRangeFormat {
  DateOnly,
  TimeOnly,
  DateTime,
  DateTimeFull,
  DateFull,
}

export class DateHelper {
  static msPerDay = 86400000;
  static msPerHour = 3600000;
  static msPerMinute = 60000;
  static monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  static is24HFormat: boolean;

  static getTimeZone(){
    return Settings.defaultZone.name;
  }

  static setTimeZone(ianaTimeZoneId: string) {
    Settings.defaultZone = ianaTimeZoneId;
    const isZoneValid = (Settings.defaultZone as unknown as Zone)?.isValid;
    if (!isZoneValid) {
      Settings.defaultZone = SystemZone.instance;
      throw new Error(`Invalid time zone ${ianaTimeZoneId}. Falling back to system time zone.`);
    }
  }

  static get fullDateTimeFormat(): DateTimeFormat {
    return this.is24HFormat ? DateTimeFormat.FullDateTime24H : DateTimeFormat.FullDateTime12H;
  }

  static get hourTimeFormat(): DateTimeFormat.Time24h | DateTimeFormat.Time12h {
    return this.is24HFormat ? DateTimeFormat.Time24h : DateTimeFormat.Time12h;
  }

  static setDay24HFormat(is24HFormat: boolean): void {
    this.is24HFormat = is24HFormat;
  }

  static initializeLocale(localeId: string): () => void {
    return () => {
      Settings.throwOnInvalid = true;
      Settings.defaultLocale = localeId;
    };
  }

  static fromISO(isoTimeString: string): Date {
    return DateTime.fromISO(isoTimeString).toJSDate();
  }

  static parseDate(date: string, format: DateTimeFormat): Date {
    if (!date) {
      return null;
    }

    const normalizedDate = TextHelper.convertNbspToSpaces(date.trim());
    const dateTime = DateTime.fromFormat(normalizedDate, format);
    return dateTime.toJSDate();
  }

  static formatDate(date: Date, format: DateTimeFormat): string {
    if (date == null) {
      return null;
    }
    const formattedDate = DateTime.fromJSDate(date).toFormat(format);
    return TextHelper.convertSpacesToNbsp(formattedDate);
  }

  static parseApiDate(date: string): Date {
    if (date == null) {
      return null;
    }

    const dateTime = DateTime.fromISO(date);
    return dateTime.toJSDate();
  }

  static formatApiDateOnly(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(ApiDateFormat.DateOnly);
  }

  static formatApiDateTime(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(ApiDateFormat.DateTime);
  }

  static formatApiUtcDateOnly(date: Date): string {
    return DateTime.fromJSDate(date).toUTC().toFormat(ApiDateFormat.DateOnly);
  }

  static formatApiUtcDateTime(date: Date): string {
    return DateTime.fromJSDate(date).toUTC().toFormat(ApiDateFormat.DateTime);
  }

  static parse12HTime(time: string): number {
    const dateTime = DateTime.fromFormat(time, DateTimeFormat.Time12hFull, { zone: 'local' });
    return dateTime.hour * 60 * 60 * 1000 + dateTime.minute * 60 * 1000;
  }

  static format12HTime(time: number): string {
    return DateTime.fromMillis(time, { zone: 'utc' }).toFormat(DateTimeFormat.Time12hFull);
  }

  static format24HTime(time: number): string {
    return DateTime.fromMillis(time, { zone: 'utc' }).toFormat(DateTimeFormat.Time24h);
  }

  static parseDuration(duration: string): number {
    if (duration == null) {
      return null;
    }

    const parsedDuration = this.parseAspNetTimeSpan(duration);
    const durationObject = Duration.fromObject(parsedDuration);
    return durationObject.toMillis();
  }

  static formatDuration(time: number): number {
    if (time == null) {
      return null;
    }

    const formattedDuration = Duration.fromMillis(time).toFormat('d.hh:mm:ss.SSSSSSS');
    return <number>(<unknown>formattedDuration);
  }

  static getDuration(time: number): { hours?: number; minutes?: number } {
    const duration = Duration.fromMillis(time).shiftTo('hours', 'minutes').toObject();
    return duration;
  }

  static formatTime(time: number, format: DateTimeFormat.Time12hHour | DateTimeFormat.Time12h | DateTimeFormat.Time24h | DateTimeFormat.Time24hHour = DateTimeFormat.Time24h): string {
    if (time == null) {
      return null;
    }

    if (format === DateTimeFormat.Time12h || format === DateTimeFormat.Time12hHour) {
      let dateTime = DateTime.now().startOf('day');
      dateTime = dateTime.plus({ milliseconds: time });
      return dateTime.toFormat(format);
    }
    return Duration.fromMillis(time).toFormat('hh:mm');
  }

  static formatDurationToHuman(time: number): string {
    if (time == null) {
      return null;
    }
    return Duration.fromMillis(time).toISOTime({ suppressMilliseconds: true });
  }

  static formatDateRange(start: Date, end: Date, format: DateRangeFormat): string {
    const interval = Interval.fromDateTimes(start, end);
    if (!interval.isValid) {
      return 'Invalid date range';
    }

    switch (format) {
      case DateRangeFormat.DateTime:
        return interval.toLocaleString(DateTime.DATETIME_MED);
      case DateRangeFormat.TimeOnly:
        return interval.toLocaleString(DateTime.TIME_SIMPLE);
      case DateRangeFormat.DateTimeFull:
      case DateRangeFormat.DateFull:
        if (this.isSameDay(start, end)) {
          return format === DateRangeFormat.DateTimeFull
            ? `${this.formatDate(start, DateTimeFormat.WeekdayShortMonthDayYear)}, ${this.formatDate(start, this.hourTimeFormat)} – ${this.formatDate(end, this.hourTimeFormat)}`
            : this.formatDate(start, DateTimeFormat.WeekdayShortMonthDayYear);
        }

        return format === DateRangeFormat.DateTimeFull
          ? `${this.formatDate(start, DateTimeFormat.WeekdayShortMonthDayYear)}, ${this.formatDate(start, this.hourTimeFormat)} – ${this.formatDate(end, DateTimeFormat.WeekdayShortMonthDayYear)}, ${this.formatDate(end, this.hourTimeFormat)}`
          : `${this.formatDate(start, DateTimeFormat.WeekdayShortMonthDayYear)} – ${this.formatDate(end, DateTimeFormat.WeekdayShortMonthDayYear)}`;
      case DateRangeFormat.DateOnly:
      default:
        if (this.isSameDay(start, end)) {
          return this.getDayLabel(start);
        }

        if (interval.start.hasSame(interval.end, 'year')) {
          return TextHelper.convertSpacesToNbsp(`${this.formatDate(start, DateTimeFormat.ShortMonthNameDay)} – ${this.getDayLabel(end)}`);
        }

        return TextHelper.convertSpacesToNbsp(`${this.formatDate(start, DateTimeFormat.ShortMonthNameDayYear)} – ${this.getDayLabel(end)}`);
    }
  }

  static isToday(date: Date): boolean {
    return new Date(date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0);
  }

  static isValidISODate(val: string) {
    try {
      return DateTime.fromISO(val).isValid;
    } catch {
      return false;
    }
  }

  static getDaysAge(date: Date): string {
    const age = DateTime.now().diff(DateTime.fromJSDate(date)).rescale().days;
    return age === 0 ? 'Today' : age === 1 ? 'Yesterday' : `${age} days ago`;
  }

  static min(a: Date, b: Date): Date {
    return a < b ? a : b;
  }

  static max(a: Date, b: Date): Date {
    return a > b ? a : b;
  }

  static isSameDay(from: Date, to: Date): boolean {
    return new Date(from).setHours(0, 0, 0, 0) == new Date(to).setHours(0, 0, 0, 0);
  }

  static getGreetingTime() {
    const currentHour = DateTime.now().hour;
    const morning = 5;
    const afternoon = 12; //24hr time to split the afternoon
    const evening = 17; //24hr time to split the evening
    const night = 21;

    if (currentHour >= morning && currentHour < afternoon) {
      return 'morning';
    } else if (currentHour >= afternoon && currentHour < evening) {
      return 'afternoon';
    } else if (currentHour >= evening && currentHour < night) {
      return 'evening';
    } else {
      return 'evening';
    }
  }

  static getHourFromNumberFormatted(value: number): string {
    const valueStr = value < 10 ? `0${value}` : value;
    return DateTime.fromISO(`${valueStr}:00`).toLocaleString(DateTime.TIME_SIMPLE);
  }

  static getDayLabel(date: Date): string {
    const yesterday = this.subtractDays(new Date(), 1);
    if (this.isToday(date)) {
      return 'Today';
    } else if (this.isSameDay(date, yesterday)) {
      return 'Yesterday';
    } else if (date.getFullYear() === new Date().getFullYear()) {
      return this.formatDate(date, DateTimeFormat.ShortMonthNameDay);
    } else {
      return this.formatDate(date, DateTimeFormat.ShortMonthNameDayYear);
    }
  }

  static addTime(date: Date, value: number, unit: DateTimeUnit): Date {
    return DateTime.fromJSDate(date)
      .plus({
        year: unit === 'year' ? value : 0,
        month: unit === 'month' ? value : 0,
        week: unit === 'week' ? value : 0,
        day: unit === 'day' ? value : 0,
        hour: unit === 'hour' ? value : 0,
        minute: unit === 'minute' ? value : 0,
        second: unit === 'second' ? value : 0,
        milliseconds: unit === 'millisecond' ? value : 0,
      })
      .toJSDate();
  }

  static addDays(date: Date, value: number): Date {
    return this.addTime(date, value, DateTimeUnit.Day);
  }

  static subtractTime(date: Date, value: number, unit: DateTimeUnit): Date {
    return this.addTime(date, -value, unit);
  }

  static subtractDays(date: Date, value: number): Date {
    return this.subtractTime(date, value, DateTimeUnit.Day);
  }

  static startOf(date: Date, unit: DateTimeUnit): Date {
    return DateTime.fromJSDate(date).startOf(unit).toJSDate();
  }

  static startOfDay(date: Date): Date {
    return this.startOf(date, DateTimeUnit.Day);
  }

  static startOfToday(): Date {
    return DateTime.now().startOf('day').toJSDate();
  }

  static endOf(date: Date, unit: DateTimeUnit): Date {
    return DateTime.fromJSDate(date).endOf(unit).toJSDate();
  }

  static endOfDay(date: Date): Date {
    return this.endOf(date, DateTimeUnit.Day);
  }

  static endOfYear(date: Date): Date {
    return this.endOf(date, DateTimeUnit.Year);
  }

  // Returns the number of days between two dates - daytime savings correction
  static getDaysCount(start: Date, end: Date): number {
    if (!start || !end) {
      return 0;
    }

    if (this.isSameDay(start, end)) {
      return 1;
    }

    // if same timezone
    const startDate = DateTime.fromJSDate(start).startOf('day');
    const endDate = DateTime.fromJSDate(end).startOf('day');
    const offsetDiff = endDate.offset - startDate.offset;
    const diff = Math.abs(endDate.diff(startDate, 'days').days) + 1;
    // summet to winter time changing correction
    if (offsetDiff < 0) {
      return diff - 1;
    }
    return diff;
  }

  static getHour(date: Date): number {
    return DateTime.fromJSDate(date).hour; // 0-23
  }

  static getMinute(date: Date): number {
    return DateTime.fromJSDate(date).minute; // 0-59
  }

  static getWeekday(date: Date): number {
    return DateTime.fromJSDate(date).weekday - 1; // 0 = Monday, 6 = Sunday
  }

  static getWeekDaysInDateRange(start: Date, end: Date): number[] {
    if (!start || !end) {
      return [];
    }

    const daysCount = this.getDaysCount(start, end);
    const startDate = DateTime.fromJSDate(this.startOfDay(start));
    if (!startDate.isValid) {
      return [];
    }
    if (daysCount < 2) {
      return [startDate.weekday];
    }
    const weekdayArray = new Array(daysCount).fill(0).map((item, index) => startDate.plus({ days: index }).weekday - 1);
    return Array.from(new Set(weekdayArray)).sort();
  }

  static getWeekdayName(dayNumber: number, format: StringUnitLength = 'long'): string {
    return Info.weekdays(format)[dayNumber];
  }

  static getMonthName(monthNumber: number, format: StringUnitLength = 'long') {
    return Info.months(format)[monthNumber];
  }

  static getMonthNames(monthNumbers: number[], format: StringUnitLength = 'long') {
    const months = Info.months(format);
    return monthNumbers.map(n => months[n - 1]);
  }

  static getDaysInDateRange(start: Date, end: Date): number[] {
    const startDate: DateTime = DateTime.fromJSDate(start).startOf(DateTimeUnit.Day);
    const endDate: DateTime = DateTime.fromJSDate(end).startOf(DateTimeUnit.Day);
    const days = [];
    for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
      days.push(date.toJSDate().getTime());
    }
    return days;
  }

  static getAllWeekdaysInDateRange(weekday: number, start: Date, end: Date): number[] {
    const allDays = this.getDaysInDateRange(start, end);
    return allDays.filter(day => this.getWeekday(new Date(day)) === weekday);
  }

  static splitDateTime(dateTime: Date): { date: Date; time: number } {
    const dt = DateTime.fromJSDate(dateTime);
    const startOfDay = dt.startOf(DateTimeUnit.Day);
    const date = startOfDay.toJSDate();
    const time = dt.diff(startOfDay).toMillis();
    return { date, time };
  }

  static joinDateTime(date: Date, time: number): Date {
    return DateTime.fromJSDate(date).plus(Duration.fromMillis(time)).toJSDate();
  }

  private static parseAspNetTimeSpan(timeSpan: string): DurationLikeObject {
    const aspNetRegex = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/;

    const match = aspNetRegex.exec(timeSpan);
    if (match == null) {
      return null;
    }

    const sign = match[1] === '-' ? -1 : 1;
    const parserDuration: DurationLikeObject = {
      year: 0,
      day: +match[2] * sign || 0,
      hour: +match[3] * sign || 0,
      minute: +match[4] * sign || 0,
      second: +match[5] * sign || 0,
      millisecond: Math.round(+match[6] * 1000 || 0) * sign,
    };

    return parserDuration;
  }

  static getUnitMultiplier(unit: DateTimeUnit): number {
    switch (unit) {
      case DateTimeUnit.Second:
        return 1000;
      case DateTimeUnit.Minute:
        return 60000;
      case DateTimeUnit.Hour:
        return 3600000;
      case DateTimeUnit.Day:
        return 86400000;
      default:
        return 1000;
    }
  }

  static getDurationInTimeUnits(duration: number): { days: number; hours: number; minutes: number } {
    const minutes = duration / this.msPerMinute;
    const hours = duration / this.msPerHour;
    const days = duration / this.msPerDay;
    return { days, hours, minutes };
  }

  // returns 0 if not a daylight saving time change, -1 if it's a winter to summer time change, 1 if it's a summer to winter time change
  static isDaylightSavingTimeChange(date: Date): number {
    const testDate = DateTime.fromJSDate(date);
    const start = testDate.startOf('day');
    const end = testDate.endOf('day');
    return start.offset - end.offset;
  }
}
