import { ActionableInsightStatus, ActionableInsightCategory, Platform } from '../../enums/generated.enums';
import { OverviewChartType } from '../../enums/overview-chart-type.enum';
import { SubPageType } from '../../enums/page-type.enum';
import { ProcessMapMetric } from '../../enums/process-map-metric.enum';
import { ProcessPerformanceChartType } from '../../enums/process-performance-chart-type.enum';
import { ScheduleType } from '../../enums/schedule-type.enum';
import { DateRange } from '../date-range.type';
import { FiltersSettings } from './filters-settings.type';
import { IncidentsFilters } from './incidents-filters.type';
import { MonitoringSettings } from './monitoring.settings.type';

export class SessionStorageData {
  globalDateRange: DateRange;

  dateRanges: { [key: string]: DateRange } = {};

  monitoringSettings: MonitoringSettings = {
    processes: { filters: {} },
    resources: { filters: {} },
    sessions: { filters: {} },
    pastSchedule: { filters: {} },
  };

  eventLogFilters: FiltersSettings;
  incidentsFilters: IncidentsFilters;

  // processes page
  monitoringProcessesDateRange?: string[] = undefined;
  processesDomainId?: string = undefined;

  // process page
  processPerformanceItemsChartType: ProcessPerformanceChartType = undefined;
  processPerformanceSessionsChartType: ProcessPerformanceChartType = undefined;

  // utilization page
  activeUtilizationSubPage: SubPageType.Licenses | SubPageType.Resources = undefined;
  utilizationScheduleDate: string = undefined;
  utilizationScheduleType: ScheduleType = undefined;
  utilizationScheduleOrderBy: string = undefined;
  selectedUtilizationFilter: 'all' | 'weekdays' | number = undefined;

  // process
  selectedProcessId: string = undefined;
  selectedMasterProcessId: string = undefined;
  currentVariantId: string = undefined;
  openDialogName: string = undefined;
  selectedNodeEdgeId: string = undefined;
  overviewChartType: OverviewChartType = undefined;
  processMap: {
    [key: string]: {
      displayedNodes?: number[];
      expandedNodeIds?: number[];
      selectedVariantIds?: string[];
      zoom?: number;
      position?: { x: number; y: number };
    };
  } = {};

  activeCostAnalysisView: string = undefined;
  selectedFlameGraphDetailId: string = undefined;
  selectedActivityTypeDetailId: string = undefined;

  // organization settings
  actionableInsightFilterState: ActionableInsightStatus | '' = undefined;
  actionableInsightSelectedCategories: ActionableInsightCategory[] = undefined;
  masterProcessTableSortDirection: string = undefined;
  processMapSettingsMetrics = [ProcessMapMetric.LogsCount, ProcessMapMetric.MeanDuration, ProcessMapMetric.AveragePrice];
  uploadFilesSelectedPlatform: Platform = undefined;
}
