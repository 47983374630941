<shared-modal [modalTitle]="'Refine cost and savings estimates'">
  <div class="body-content" modalBody>
    <p>
      There were <b>{{ statistics.casesCount | format: FormatType.Number : false : true }}</b> {{ taskTypeLabel }} in the process in the period of {{ formattedProcessInterval }}. That would average to
      <b>{{ statistics.monthCasesCount | format: FormatType.Number : false : true }}</b> {{ taskTypeLabel }} a&nbsp;month&nbsp;/&nbsp;
      <b>{{ statistics.yearCasesCount | format: FormatType.Number : false : true }}</b> {{ taskTypeLabel }} a&nbsp;year.
    </p>
    <p>If you know the number of {{ taskTypeLabel }} in the process is likely to be different in the future, you can enter it below to get more accurate cost and savings estimates.</p>

    <form [formGroup]="form" class="estimation-form-content" (ngSubmit)="updateEstimation()">
      <b>Target number of {{ taskTypeLabel }} in the process:</b>

      <div class="form-inputs">
        <input #estimationInput name="estimationValue" formControlName="estimation" class="form-control" aria-label="estimation value" mask="separator.0" thousandSeparator="," autofocus />
        <mat-button-toggle-group formControlName="estimationPeriod" hideSingleSelectionIndicator="true" aria-label="Estimation period">
          <mat-button-toggle [value]="IntervalType.Month">per month</mat-button-toggle>
          <mat-button-toggle [value]="IntervalType.Year">per year</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="form-buttons">
        <button type="button" class="btn btn-inverse animate" *ngIf="estimationChanged" (click)="resetEstimation()">
          <svg-icon key="repeat" fontSize="15px"></svg-icon>
          <span class="m-l-8">Reset to original value</span>
        </button>
        <button type="submit" class="btn btn-primary animate" [disabled]="form.invalid">Recalculate estimates</button>
      </div>
    </form>
  </div>
</shared-modal>
