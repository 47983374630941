import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from '../../helpers/array.helper';
import { SelectFixedItem, SelectGroup, SelectItem, Selection } from './select-item.type';

@Component({
  selector: 'shared-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SelectWithSearchComponent }],
})
export class SelectWithSearchComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() selection: Selection;
  @Input() items: SelectItem[];
  @Input() fixedItems: SelectFixedItem[] = [];
  @Input() multiple = false; // if multiple === true then SimpleChanges has data in changes.selection else changes.items
  @Input() set selectAllText(value: string) {
    this._selectAllText = value;
    this.hasSelectAllOption = value;
    if (!this.fixedItems.some(i => i.type === 'all')) {
      this.fixedItems.unshift({ id: 'all', label: value, type: 'all' });
    }
  }
  get selectAllText(): string {
    return this._selectAllText;
  }
  @Input() placeholder: string;
  @Input() searchPlaceholder = 'Search...';
  @Input() panelClass: string | string[] | Set<string> | { [key: string]: any };
  @Input() selectClass: string | string[] | Set<string> | { [key: string]: any };
  @Input() disabled = false;
  @Input() debounceTime = 300; // Debounce time in milliseconds
  @Output() selectionChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();
  hasSelectAllOption: any;

  get selectionModel(): SelectItem | SelectItem[] {
    return this._selectionModel;
  }

  set selectionModel(value: SelectItem | SelectItem[]) {
    this.filterItems();
    this.updateSelection(value);
    this.markAsTouched();
    this.selectionChange.emit(this.selection);
    this.onChange(this.selection);
  }

  private filterTimeout: any;
  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this._filter = value;
      this.filterItems();
    }, this.debounceTime);
  }

  get isInvalid(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  groups$ = new BehaviorSubject<SelectGroup[]>([]);

  allSelected = false;

  private _selectAllText: string;
  private _selectionModel: SelectItem | SelectItem[];
  private _filter = '';
  private formControl: FormControl;
  private onChange = (value: Selection) => {};
  private onTouched = () => {};
  private touched = false;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection?.firstChange || changes.items?.firstChange) {
      this.filterItems();
      this.updateSelection(this.selection);
      return;
    }

    if (changes.items) {
      if (this.selection === 'all') {
        this._selectionModel = this.items.slice();
      }
      this.filterItems();
    }

    // logic for filter panel
    if (changes.selection && changes.items) {
      this.updateSelection(this.selection);
    }
    // logic for multiple select
    else if (this.multiple && changes.selection?.previousValue?.id !== changes.selection?.currentValue?.id) {
      this.updateSelection(changes.selection.currentValue as Selection);
    }
    // logic for single select
    else if (!this.multiple && changes.items && changes.items.previousValue?.id !== changes.items.currentValue?.id) {
      this.updateSelection(this.selection);
    } else {
      this.updateFixedItemsSelection();
    }
    this.cdr.markForCheck();
  }

  async ngOnInit(): Promise<void> {
    try {
      const ngControl = this.injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else {
        this.formControl = (ngControl as FormControlDirective).form;
      }
    } catch {
      this.formControl = this.injector.get(FormControlName, null)?.control as FormControl;
    }
  }

  onClosed(select: MatSelect): void {
    this.markAsTouched();
    // Workaround for MatSelect not emitting blur event - it is needed to make it work in <form> if "updateOn: 'blur'" is used
    const element = select._elementRef.nativeElement as HTMLElement;
    element.focus();
    element.blur();
    this.closed.emit(this.selection);
  }

  onSelectAllChanged(event: boolean): void {
    this.updateSelection(event === true ? this.items : []);
  }

  onSelectNoneChanged(event: boolean) {
    this.updateSelection(event === true ? [this.fixedItems.find(f => f.type === 'none')] : []);
  }

  private filterItems() {
    const items = this.items ?? [];
    const filterValueLowercase = this.filter.toLowerCase();
    const filteredItems = this.filter === '' ? items : items.filter(i => i.label.toLowerCase().includes(filterValueLowercase));
    const groupedItems = groupBy(filteredItems, i => i.group);
    const groups: SelectGroup[] = Array.from(groupedItems.entries())
      .map(([label, items]) => ({ label, items }))
      .sort((a, b) => a.label?.localeCompare(b.label));
    this.groups$.next(groups);
  }

  private updateSelection(value: Selection): void {
    if (this.items == null || value == null) {
      return;
    }
    this._selectionModel = value === 'all' || (Array.isArray(value) && value.length === 1 && value.some(v => v.id === 'all')) ? this.items.slice() : value;
    this.selection = this._selectionModel;
    this.updateFixedItemsSelection();
    this.cdr.markForCheck();
  }

  private updateFixedItemsSelection(): void {
    if (this.selectionModel == null) {
      return;
    }
    const selectionArray = Array.isArray(this.selectionModel) ? this.selectionModel : [this.selectionModel];
    const fixedItemMarkers = new Map(this.fixedItems.map(item => [item.type, item.id]));
    const selectedArrayWithoutFixedItems = selectionArray.filter(i => !this.fixedItems.some(f => f.id === i.id));

    const isAllSelected =
      this.items.length === selectedArrayWithoutFixedItems.length ||
      (selectedArrayWithoutFixedItems.length === 0 && !this.multiple) ||
      (this.multiple && selectionArray.length === 1 && selectionArray[0].id === fixedItemMarkers.get('all'));

    if (fixedItemMarkers.has('all') && isAllSelected) {
      this.allSelected = true;
      if (!selectionArray.some(i => i.id === fixedItemMarkers.get('all'))) {
        if (this.multiple) {
          this._selectionModel = [this.fixedItems.find(f => f.type === 'all'), ...this.items];
        } else {
          this._selectionModel = this.fixedItems.find(f => f.type === 'all');
        }
      }
    } else {
      this.allSelected = false;
      if (selectionArray.some(i => i.id === fixedItemMarkers.get('all'))) {
        this._selectionModel = selectedArrayWithoutFixedItems;
      }
      if (fixedItemMarkers.has('none')) {
        if (selectedArrayWithoutFixedItems.length === 0) {
          // todo update model
          return;
        } else if (selectedArrayWithoutFixedItems.length < this.items.length && selectionArray.some(i => i.id === fixedItemMarkers.get('none'))) {
          this._selectionModel = selectedArrayWithoutFixedItems;
        } else {
          // if none is selected then uncheck it
          if (selectionArray.some(i => i.id === fixedItemMarkers.get('none'))) {
            this.selectionModel = selectedArrayWithoutFixedItems;
          }
        }
      }
    }
  }

  writeValue(value: Selection): void {
    this.updateSelection(value);
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
