import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

export class FormHelper {
  static isFieldInvalid(form: FormGroup, field: string, validations: string[] = null): boolean {
    const control = form.get(field);
    return this.isControlInvalid(control, validations);
  }

  static isArrayFieldInvalid(form: FormArray, index: number, field: string, validations: string[] = null): boolean {
    const control = form.at(index).get(field);
    return this.isControlInvalid(control, validations);
  }

  static isControlInvalid(control: AbstractControl, validations: string[] = null): boolean {
    if (control == null) {
      return false;
    }

    if (validations != null) {
      return validations.some(v => control.hasError(v) && (control.touched || control.dirty));
    }

    return control.invalid && (control.touched || control.dirty);
  }

  static getFieldErrors(form: FormGroup, field: string): string[] {
    const control = form.get(field);
    if (control == null) {
      return [];
    }

    return Object.keys(control.errors || {});
  }

  static validateAllFormFields(formGroup: FormGroup | FormArray): void {
    if (!formGroup) {
      return;
    }
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }

  static findInvalidControlsNames(formGroup: FormGroup | FormArray): string[] {
    const invalid: string[] = [];
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control.invalid) {
        invalid.push(field);
      }
    });
    return invalid;
  }

  static setEnabled(control: AbstractControl, enabled: boolean, emitEvent: boolean = null): void {
    if (enabled) {
      control.enable({ emitEvent });
    } else {
      control.disable({ emitEvent });
    }
  }

  static setFocusInputSelectElement(elementName: string): void {
    setTimeout(() => {
      const element = document.getElementsByName(elementName)[0];
      if (!element) {
        return;
      }

      if (element instanceof HTMLSelectElement || element instanceof HTMLInputElement) {
        element.focus();
      } else {
        const select = element.querySelector('select');
        if (select) {
          select.focus();
          return;
        }
        const input = element.querySelector('input');
        if (input) {
          input.focus();
          return;
        }
      }
    }, 0);
  }

  public static initializeControl<T>(name: string, shouldBeVisible: boolean, getControl: () => AbstractControl<T>, form: FormGroup, formSubscriptions: Map<string, Subscription>, initializeControls: () => void, initializeOnChange = false, shouldBeEnabled = true): void {
    const isVisible = form.contains(name);
    if (shouldBeVisible && !isVisible) {
      form.addControl(name, getControl());
      if (initializeOnChange) {
        formSubscriptions.set(name, form.get(name).valueChanges.subscribe(() => initializeControls()));
      }
    } else if (!shouldBeVisible && isVisible) {
      form.removeControl(name);
      if (initializeOnChange) {
        formSubscriptions.get(name)?.unsubscribe();
      }
    }

    const control = form.get(name);
    if (control == null) {
      return;
    }
    const isEnabled = control.enabled;
    if (shouldBeEnabled && !isEnabled) {
      control.enable();
    }
    if (!shouldBeEnabled && isEnabled) {
      control.disable();
    }
  }

  // VALIDATORS
  public static atLeastOneTrueValidator(control: AbstractControl): ValidationErrors {
    const valid = (control as FormArray).controls.some(c => c.value === true);
    return valid ? null : { atLeastOneError: true };
  }

  public static emptyOrUniqueValidator(control: AbstractControl): ValidationErrors {
    const array = (control as FormControl).value as string[];
    const valid = array.length === 0 || array.length === new Set(array).size;
    return valid ? null : { atLeastOneError: true };
  }

  public static onceDateTimeValidator(control: AbstractControl): ValidationErrors {
    const valid = control.value > new Date();
    return valid ? null : { atLeastOneError: true };
  }

  public static parametersGroupValidator(control: AbstractControl): ValidationErrors {
    const f = (control as FormGroup).controls;
    const valid = (f.name.value == null && f.value.value == null) || (f.name.value != null && f.value.value != null && f.name.value !== '' && f.value.value !== '');
    return valid ? null : { atLeastOneError: true };
  }

  public static emptyIdValidator(control: AbstractControl): ValidationErrors {
    const valid = control.value != null && control.value !== '00000000-0000-0000-0000-000000000000';
    return valid ? null : { isEmpty: true };
  }

  public static mustExistValidator(valuesGetter: () => { id: string }[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const values = valuesGetter();
      const valid = control.value != null && (values == null || values.map(v => v.id).includes(control.value));
      return valid ? null : { mustExist: true };
    };
  }

  public static uriComponentValidator(control: AbstractControl): ValidationErrors {
    const valid = control.value == null || control.value == encodeURIComponent(control.value);
    return valid ? null : { isNotValidUriComponent: true };
  }

  public static greaterThanValidator(field: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const group = control.parent;
      if (!group) {
        return null;
      }
      const fieldToCompare = group.get(field);
      if (!fieldToCompare) {
        return null;
      }
      const isLessThan = Number(fieldToCompare.value) < Number(control.value);
      return isLessThan ? null : { greaterThanError: true };
    };
  }

  public static daysValidator(control: AbstractControl): ValidationErrors {
    const array = (control.value as string)
      .split(',')
      .map(v => v.trim())
      .filter(v => v !== '')
      .map(v => (v.match(/^-?\d+$/) ? parseInt(v) : NaN));
    const valid = array.every(v => !isNaN(v) && v >= -31 && v <= 31 && v !== 0) && array.length > 0;
    return valid ? null : { atLeastOneError: true };
  }
}
