import { Injectable } from '@angular/core';
import { REACTIVE_NODE } from '@angular/core/primitives/signals';
import { MatSnackBar } from '@angular/material/snack-bar';
import SweetAlert from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class PopupService {
  constructor(private matSnackBar: MatSnackBar) {}

  async showInfo(message: string, title?: string, okButtonText?: string, isHtml = false, standardClose = true, autoWidth = false, showCloseButton = false): Promise<void> {
    await SweetAlert.fire({
      title,
      text: isHtml ? null : message,
      html: isHtml ? message : null,
      showConfirmButton: true,
      confirmButtonText: okButtonText || 'OK',
      showCloseButton: showCloseButton,
      allowOutsideClick: standardClose,
      allowEscapeKey: standardClose,
      width: autoWidth ? 'auto' : null,
      customClass: {
        confirmButton: 'swal2-confirm link',
      },
    });
  }

  async showOkCancelQuestion(title: string, message: string, okButtonText?: string, cancelButtonText?: string, isPositive = false): Promise<boolean> {
    const sweetAlert = SweetAlert.mixin({
      title,
      html: message,
      showConfirmButton: true,
      confirmButtonText: okButtonText ?? 'OK',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      reverseButtons: true,
      customClass: {
        confirmButton: `swal2-confirm ${isPositive ? 'primary' : 'danger'}`,
      },
    });
    return (await sweetAlert.fire()).isConfirmed;
  }

  async showDeleteQuestion(title: string, message: string, deleteButtonText?: string, cancelButtonText?: string): Promise<boolean> {
    const sweetAlert = SweetAlert.mixin({
      title,
      html: message,
      showConfirmButton: true,
      confirmButtonText: deleteButtonText ?? 'Delete',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal2-confirm danger',
      },
    });
    return (await sweetAlert.fire()).isConfirmed;
  }

  async showCustomAlertWithRadioButtons(title: string, options: any, selected: string, okButtonText?: string, cancelButtonText?: string, isPositive = false): Promise<string> {
    const result = await SweetAlert.fire<string>({
      title,
      input: 'radio',
      inputOptions: options,
      inputValue: selected,
      showConfirmButton: true,
      confirmButtonText: okButtonText ?? 'OK',
      showCancelButton: true,
      cancelButtonText: cancelButtonText ?? 'Cancel',
      showCloseButton: true,
      reverseButtons: true,
      customClass: {
        confirmButton: `swal2-confirm ${isPositive ? 'primary' : 'danger'}`,
      },
    });

    return result.isConfirmed ? result.value : null;
  }

  async showQuestion(title: string, inputValue: string, confirmLabel: string): Promise<string> {
    const sweetAlert = SweetAlert.mixin({
      customClass: {
        confirmButton: 'swal2-confirm primary',
      },
      didOpen: () => {
        sweetAlert.getInput()?.addEventListener('keyup', e => {
          const { value } = <HTMLInputElement>e.target;
          if ((value || '').trim().length === 0) {
            sweetAlert.disableButtons();
          } else {
            sweetAlert.enableButtons();
          }
        });
      },
    });

    const result = await sweetAlert.fire<string>({
      html: `<label for="swal-input">${title}:</label>`,
      input: 'text',
      inputValue,
      inputAttributes: {
        id: 'swal-input',
        autocapitalize: 'off',
        class: 'pointee-input',
      },
      inputValidator: r => (!r || r.trim().length < 1) && 'enter valid input',
      confirmButtonText: confirmLabel,
      showCloseButton: true,
      allowOutsideClick: true,
      backdrop: true,
      showClass: {
        popup: 'animate__animated animate__fadeIn',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut',
      },
    });

    return result.value;
  }

  showSnackBar(message: string, duration?: number): void {
    this.matSnackBar.open(message, null, {
      duration: duration || 10000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: 'snackbar',
    });
  }
}
