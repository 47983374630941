import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'shared-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ChipsInputComponent }],
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() autocompleteSearchList: string[];
  @Input() maxCount?: number;
  @ViewChild('chipInput', { static: true }) chipInput: ElementRef;

  values: string[] = [];
  autocompleteValues: string[];
  disabled: boolean;
  withRefresh = false;
  showSearches: boolean = false;
  activeIndex = -1;
  private onChange = (values: string[]) => {};
  private onTouched = () => {};
  private touched = false;
  private previousValue: string | null = null;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    fromEvent(this.chipInput.nativeElement, 'input')
      .pipe(debounceTime(300))
      .subscribe(() => this.onChanged());
  }

  onChanged() {
    const value = this.chipInput.nativeElement.value;
    this.autocompleteValues = this.autocompleteSearchList.filter(item => item.toLowerCase().includes(value.toLowerCase()));
    this.showSearches = value && this.autocompleteValues.length > 0;
    if (this.previousValue !== value) {
      this.activeIndex = -1;
    } else {
      this.activeIndex = Math.min(this.activeIndex, this.autocompleteValues.length - 1);
    }
    this.cdr.markForCheck();
  }

  onKeyDown(event: KeyboardEvent) {
    const value = this.chipInput.nativeElement.value;

    if (event.key === ',' || event.key === ' ') {
      event.preventDefault();
      this.processInput();
    } else if (event.key === 'Delete' || event.key === 'Backspace') {
      if (value == null || value.length === 0) {
        event.preventDefault();
        event.stopPropagation();

        // remove last value when Backspace key is pressed and there is no and was no value in the input
        if (this.previousValue == null || this.previousValue.length === 0) {
          this.removeLastValue();
        }
      }
      // Update previousValue before processing the current value
      this.previousValue = value;
      this.showSearches = false;
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      if (this.showSearches) {
        if (event.key === 'ArrowDown') {
          this.activeIndex = (this.activeIndex + 1) % this.autocompleteValues.length;
          event.preventDefault();
        } else if (event.key === 'ArrowUp') {
          this.activeIndex = (this.activeIndex - 1 + this.autocompleteValues.length) % this.autocompleteValues.length;
          event.preventDefault();
        }
      }
    }
    this.cdr.markForCheck();
  }

  autoselectKeyDown(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.showSearches && this.activeIndex >= 0 && this.activeIndex < this.autocompleteValues.length) {
        this.setItem(this.autocompleteValues[this.activeIndex]);
        this.activeIndex = -1;
      } else {
        this.processInput();
      }
    }
  }

  private processInput(item?: string) {
    let value = item ?? this.chipInput.nativeElement.value;
    value = value.replace(/,/g, '').trim();
    if (!value) {
      return;
    }
    this.markAsTouched();
    // each value in the input should be unique
    if (this.values.includes(value)) {
      this.chipInput.nativeElement.value = null;
      return;
    }
    this.values.push(value);
    this.showSearches = false;
    this.onChange(this.values);
    this.chipInput.nativeElement.value = null;
    this.cdr.markForCheck();
  }

  private removeLastValue() {
    if (this.values.length > 0) {
      this.values.splice(-1);
      this.markAsTouched();
      this.onChange(this.values);
    }
  }

  setItem(item: string) {
    // each value in the input should be unique
    if (this.values.includes(item)) {
      this.chipInput.nativeElement.value = null;
      return;
    }
    this.values.push(item);
    this.showSearches = false;
    this.activeIndex = -1;
    this.chipInput.nativeElement.value = '';
    this.onChange(this.values);
  }

  removeValue(event: MouseEvent, index: number) {
    event.preventDefault();
    this.markAsTouched();
    this.values.splice(index, 1);
    this.onChange(this.values);
  }

  writeValue(values: string[]): void {
    this.values = values;
  }

  registerOnChange(onChange: (values: string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
